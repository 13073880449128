import { RulesData } from "@/utils/apis/rules_repository";
import React from "react";
import { TextField } from '@mui/material';
import { Text } from '../../../../Text';
import { SelectDropDown } from '../../../../inputs/SelectDropDown';
import AppliedTenants from "../DetailComponents/AppliedTenants";

// for applied / other users, groups and tenants -> show AutoComplete_Input field
// pass applied and non applied users, groups and tenants to each field
// store values here in the state. Pass it down with submit option. pass new rule up to the parent component
interface EarlyArrivalInterface {
    [tenant: string]: number; // Use tenant names as keys and store string values
}
interface EarlyArrivalProps {
    rule: RulesData;
    tenants: string[];
    appliedTenants: string[];
    earlyArrivals:EarlyArrivalInterface;
    setEarlyArrivals: React.Dispatch<React.SetStateAction<EarlyArrivalInterface>>;
    setAppliedTenants: React.Dispatch<React.SetStateAction<string[]>>;
    setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EarlyArrival: React.FC<EarlyArrivalProps> = ({ rule, tenants, appliedTenants, setAppliedTenants, earlyArrivals, setEarlyArrivals, setDataChanged }) => {
    return (
        <>
            <AppliedTenants tenants={tenants} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged} isDisabled={true}/>
            
            <Text as='p'> Early Arrivals </Text>
            
            {appliedTenants.map((tenant: any) => {
                return (
                    <div className='wrapper' key={tenant}>
                        <div className='bg-white py-4'>
                            <TextField
                                id="clean"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                contentEditable={false}
                                margin='none'
                                disabled
                                defaultValue={tenant}
                            />
                        </div>
                        <div>
                            <SelectDropDown className="bg-white" onChange={(e) => {
                                setEarlyArrivals({ ...earlyArrivals, [tenant]: parseInt(e.target.value) });
                                setDataChanged(true);
                            }
                            } value={
                                earlyArrivals[tenant] ? earlyArrivals[tenant] : parseInt(rule.value)
                            }>
                                <option value={15}>15 Minutes</option>
                                <option value={30}>30 Minutes</option>
                                <option value={45}>45 Minutes</option>
                                <option value={60}>1 Hour</option>
                                <option value={120}>2 Hours</option>
                                <option value={180}>3 Hours</option>
                                <option value={240}>4 Hours</option>
                                <option value={360}>6 Hours</option>
                                <option value={720}>12 Hours</option>
                            </SelectDropDown>
                        </div>
                    </div>
                )
            }
            )}
        </>
    );
};

export default EarlyArrival;