import React from 'react';
import { RulesData, updateRule } from '../../../../../utils/apis/rules_repository';
import Switch from "@mui/material/Switch";
import { Row } from "react-table";
import ViewDetails from '../../ViewDetails';
import { DetailsType } from "../../../../../utils/enum";

const handleChange = (event: React.ChangeEvent<HTMLInputElement>, rowData: any) => {

  const rule: RulesData = {
    title: rowData.original.title,
    summary: rowData.original.summary,
    description: rowData.original.description,
    // only send the id of the user
    users: rowData.original.users.map((user: any) => ({ userId: user.userId, name: "" })),
    userGroups: rowData.original.userGroups,
    tenants: rowData.original.tenants,
    status: event.target.checked,
    statuses: rowData.original.statuses,
    value: rowData.original.value,
    mobileOption: rowData.original.mobileOptions,
  }
  updateRule(rule);
};

export const SuperAdminRulesColumn = () => [
  {
    Header: <div style={{ textAlign: 'center' }}>Title</div>,
    accessor: "title",
    Cell: ({ value }: { value: string }) => <div className='w-[100px] flex items-center justify-center text-center pl-10'>{value}</div>,
    width: 6
  },
  {
    Header: <div style={{ textAlign: 'center' }}>Summary</div>,
    accessor: "summary",
    Cell: ({ value }: { value: string }) => <div className='flex justify-center items-center  text-center'>{value}</div>,
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Applied Users</div>,
    accessor: "users",
    Cell: ({ value }: { value: any[] }) => <div className='text-center'>{value.length === 0 ? "N/A": 
    value.map((user, index) => {
        return <span key={index}>{user.name}{index !== value.length - 1 ? ", " : ""}</span>
    })
    }</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Applied User Groups</div>,
    accessor: "userGroups",
    Cell: ({ value }: { value: any[] }) => <div className='text-center'>{!value || value.length === 0 ? "N/A": 
    value.map((group, index) => {
        return <span key={index}>{group.name}{index !== value.length - 1 ? ", " : ""}</span>
    })
    }</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Applied Tenants</div>,
    accessor: 'tenants',
    Cell: ({ value }: { value: string[] }) => <div className='flex justify-center'>{value.length === 0 ? "N/A": 
    value.map((tenant, index) => {
        return <span key={index}>{tenant}{index !== value.length - 1 ? ", " : ""}</span>
    })
    }</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Rule Status</div>,
    accessor: 'status',
    Cell: ({ row }: { row: Row<RulesData> }) => <div className='flex justify-center'>{<Switch 
      onChange={(event) => {
        if (row.original.title === "Grace Period") {
          event.target.checked = true;
          // return;
        }
        row.original.status = event.target.checked;
        handleChange(event, row)
      }} color="warning" 
        defaultChecked={row.original.status}
    />}</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}></div>,
    accessor: "details",
    Cell: ({ row }: { row: Row<RulesData> }) => <div className='text-center'>{<ViewDetails type={DetailsType.RULES} data={row.original as RulesData}></ViewDetails>}</div> // new
  },
];