// PROD
const config = {
    apiKey: "AIzaSyBu9V4NYNu90lhMe8hANqSFbWUWr659-wM",
    authDomain: "barangarooadmin.firebaseapp.com",
    databaseURL: "https://barangarooadmin-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "barangarooadmin",
    storageBucket: "barangarooadmin.appspot.com",
    messagingSenderId: "403919169723",
    appId: "1:403919169723:web:ea809ad7fcf537568b619e",
    measurementId: "G-L50B6YHYZ3",
};

export function getFirebaseConfig() {
    if (!config || !config.apiKey) {
        throw new Error("No Firebase configuration object provided." + "\n" + "Add your web app's configuration object to firebase-config.ts");
    } else {
        return config;
    }
}

// TEST
// const config = {
//   apiKey: "AIzaSyC2szaJGq_BbXFAjy_1ljTWDLwClzNlqCI",
//   authDomain: "barangarooadmin-test.firebaseapp.com",
//   projectId: "barangarooadmin-test",
//   storageBucket: "barangarooadmin-test.appspot.com",
//   messagingSenderId: "360044705012",
//   appId: "1:360044705012:web:e7f63c640e87c4e9f61aa1",
//   measurementId: "G-CKV9X70DVH"
// };

// export function getFirebaseConfig() {
//   if (!config || !config.apiKey) {
//     throw new Error('No Firebase configuration object provided.' + '\n' +
//     'Add your web app\'s configuration object to firebase-config.ts');
//   } else {
//     return config;
//   }
// }
