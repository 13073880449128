import axios from 'axios';
import { addOverstayEventToBooking, deleteValetBooking, getValetBookingDetails } from './bookings_repository';
import { showToast } from '../toast';
import log from '../logger';

const stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
const stripeApiUrl = process.env.REACT_APP_STRIPE_API_URL;


export const createStripeCustomer = async (paymentMethodId: string, email: string, phone: string) => {
    const customer = await axios.post(
        `${stripeApiUrl}/customers`,
        {
            payment_method: paymentMethodId,
            email,
            phone,
            invoice_settings: {
                default_payment_method: paymentMethodId,
            },
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${stripeSecretKey}`,
            },
        }
    ).then(res => res.data);

    return customer;
};

export const createPaymentIntent = async (
    customerId: string,
    amount: number,
    paymentMethodId: string,
    description: string
) => {
    const paymentIntent = await axios.post(
        `${stripeApiUrl}/payment_intents`,
        {
            customer: customerId,
            amount: amount * 100, // Amount in cents
            currency: 'usd',
            payment_method: paymentMethodId,
            off_session: true,
            confirm: true,
            description,
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${stripeSecretKey}`,
            },
        }
    ).then(res => res.data);

    return paymentIntent;
};

export const handleOverstayPayment = async (bookingsData: any): Promise<boolean> => {
    const bookingData = await getValetBookingDetails(bookingsData.bookingId);
    const { customerId, plateNumber, venue, selectedDate } = bookingData;

    try {
        // Fetch customer details from Stripe
        const customer = await axios.post(
            `${stripeApiUrl}/customers/${customerId}`,
            {},
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        ).then(res => res.data);

        // Create payment intent for overstay fee
        const overstayPayment = await axios.post(
            'https://api.stripe.com/v1/payment_intents',
            {
                amount: 6 * 100, // Overstay fee in cents
                currency: 'usd',
                customer: customerId,
                payment_method: customer.invoice_settings.default_payment_method,
                off_session: true,
                confirm: true,
                description: `Overstay fee for vehicle ${plateNumber} at ${venue} on ${selectedDate}`,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        ).then(res => res.data);

        if (overstayPayment && overstayPayment.status === 'succeeded') {
            console.log("Overstay charge successful:", overstayPayment);
            await addOverstayEventToBooking(bookingData.bookingId);
            log({collection: 'bookings', documentId: bookingData.bookingId, message: 'Overstay charged', type: 'update'});
            showToast('Overstay charge successful', { type: 'success' });
            return true;
        } else {
            console.error("Overstay charge failed:", overstayPayment);
            showToast('Overstay charge failed', { type: 'error' });
            return false;
        }
    } catch (error) {
        console.error("Error processing overstay payment:", error);
        showToast('Error processing overstay payment', { type: 'error' });
        return false;
    }
};

export const handleValetCancelBooking = async (bookingId: any): Promise<boolean> => {
    try {
        const bookingData = await getValetBookingDetails(bookingId);
        const { paymentId } = bookingData;

        // Process the refund
        const { data } = await axios.post(
            `${stripeApiUrl}/refunds`,
            {
                payment_intent: paymentId,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${stripeSecretKey}`,
                },
            }
        );

        if (data.status === 'succeeded') {
            await deleteValetBooking(bookingId);
            log({collection: 'bookings', documentId: bookingId, message: 'Valet booking canceled', type: 'delete'});
            showToast('Booking canceled successfully', { type: 'success' });
            return true;
        } else {
            console.error("Refund failed:", data);
            showToast('Refund failed', { type: 'error' });
            return false;
        }
    } catch (error) {
        console.error('Error canceling booking:', error);
        showToast('Error canceling booking', { type: 'error' });
        return false;
    }
};

export async function verifyRecaptchaFrontend(recaptchaToken: string) {
    const SECRET_KEY_V3 = "6LdE_icqAAAAAJL-8Awpz9f2cIucEkTD6C5vwyLb";
    const url = `https://www.google.com/recaptcha/api/siteverify?secret=${SECRET_KEY_V3}&response=${recaptchaToken}`;

    try {
        const response = await axios.get(url, {
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error verifying reCAPTCHA:", error);
        throw error;
    }
}