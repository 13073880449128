import axios from 'axios';

const baseUrl = 'https://australia-southeast1-barangarooadmin.cloudfunctions.net'; // Replace with your actual base URL

const headers = {
  'Content-Type': 'application/json',
};

const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
  });

export type ApiResponse<T> = {
    data: T | null;
    error: string | null;
  };

export enum RequestType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface Failure {
  // Define your failure structure
}

const _logger = {
  i: (message: string) => console.log(message),
  e: (message: string) => console.error(message),
};

export async function callApi<T>(
    endpoint: string,
    body: Record<string, unknown> | undefined | T[],
    requestType: RequestType
  ): Promise<ApiResponse<T>> {
    _logger.i(`API Request: ${requestType} ${baseUrl}/${endpoint}`);
    _logger.i(`API Request Body: ${JSON.stringify(body)}`);
  
    try {
      const response = await axiosInstance({
        url: `${baseUrl}/${endpoint}`,
        method: requestType,
        data: JSON.stringify(body),
        headers,
      });
  
      _logger.i(`API Response: ${response.status} ${response.statusText}`);
  
      return {
        data: response.data,
        error: null,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        _logger.e(`API Response: ${error.response?.status} ${error.response?.statusText}`);
        return {
          data: null,
          error: error.response?.statusText ?? 'Unknown error',
        };
      } else {
        // Handle non-Axios errors if needed
        _logger.e(`API Error:`);
        return {
          data: null,
          error: 'An unexpected error occurred',
        };
      }
    }
  }