
import React from 'react'
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from '@stripe/stripe-js'
import PaymentForm from './PaymentForm'

const PUBLIC_KEY = `${process.env.REACT_APP_STRIPE_P_KEY}`;
const stripeTestPromise = loadStripe(PUBLIC_KEY!)
// Define the types for the props
interface PaymentFormProps {
  selectedDate: Date | string;
  venue: string;
  phone: string;
  plateNumber: string;
  email: string;
  bookingId: string;
  status: boolean;
}

const StripeContainer = ({ selectedDate, venue, phone, plateNumber, email, bookingId, status }: PaymentFormProps) => {
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm
        selectedDate={selectedDate}
        venue={venue}
        phone={phone}
        plateNumber={plateNumber}
        email={email}
        bookingId={bookingId}
        status={status}
         />
    </Elements>
  );
};

export default StripeContainer;
