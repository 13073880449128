// import { Text } from "../../Text";
// import { Button } from "../../CustomButton";
// import { getDate, getTime } from "../../../../utils/helper";
// import { BookingsData } from "@/utils/apis/bookings_repository";
// import EventsChip from "../../table/EventsChip";
// import { getColor } from "../../table/StatusChip";

// interface BookingDetailsBodyProps {
//     booking: BookingsData;
// }

// const BookingDetailsBody = (booking: BookingDetailsBodyProps) => {
//     return (
//         <div className="whitespace-nowrap overflow-y-auto">
//             <Text as="h3">Booking Type</Text>
//             <div className="flex flex-row space-x-8 mt-6 items-center">
//                 <div className={`w-44 bg-primary py-3 px-3 flex justify-center text-white rounded-md`}>{booking.booking.bookingType} Booking</div>
//             </div>
//             <Text className="mt-10" as="h3">{booking.booking.bookingType === "Single" ? "Booking Date" : "Booking Validity"}</Text>
//             <Text className="mt-6 text-secondaryText">{booking.booking.bookingType === "Single" ? getDate(booking.booking.startTime) : "Valid From " + getDate(booking.booking.originalBooking?.startTime!) + " to " + getDate(booking.booking.originalBooking?.endTime!)}</Text>
//             <div className="flex flex-row space-x-8 items-center">
//                 <div className="flex flex-col">
//                     <Text as="h3">Start Time</Text>
//                     <Text className="text-secondaryText">{getTime(booking.booking.startTime)}</Text>
//                 </div>
//                 <div className="flex flex-col">
//                     <Text as="h3">End Time</Text>
//                     <Text className="text-secondaryText">{getTime(booking.booking.endTime)}</Text>
//                 </div>
//             </div>
//             <Text className="mt-10" as="h3">Current Status</Text>
//             <div className="mt-4">
//             <div className={`${getColor(booking.booking.status)} w-44 bg-primary py-3 px-3 flex justify-center rounded-md`}>{booking.booking.status} Booking</div>
//             </div>
//             <Text className="mt-10" as="h3">Events</Text>
//             <div className="flex flex-row space-x-8 mt-4 overflow-x-auto whitespace-nowrap items-center">{
//                 booking.booking.events === undefined ? <div>
//                     <Text className="text-secondaryText">No events Yet</Text>
//                 </div> : booking.booking.events.map((events) => {
//                     return (
//                         <EventsChip status={events.status} type={events.type} timestamp={events.timestamp} key={events.status} />
//                     )
//                 }, [])
//             }
//             </div>
//             <Text className="mt-10" as="h3">Available Plates</Text>
//             <div className="flex flex-row space-x-8 mt-6 items-center">{
//                 booking.booking.plate.map((plate) => {
//                     // make sure plates are not empty 
//                     if (plate === "") {
//                         return null;
//                     }
//                     return (
//                         <Button variant="default" className="w-44 text-white">{plate}</Button>
//                     )
//                 }, [])
//             }</div>
//             <div className="mt-32">

//             </div>
//         </div>
//     );
// }

// export default BookingDetailsBody;

import React from 'react';
import { Text } from "../../Text";
import { Button } from "../../CustomButton";
import { getDate, getTime } from "../../../../utils/helper";
import { BookingsData } from "@/utils/apis/bookings_repository";
import EventsChip from "../../table/EventsChip";
import { getColor } from "../../chips/StatusChip";

interface BookingDetailsBodyProps {
    booking: BookingsData;
}

const BookingDetailsBody: React.FC<BookingDetailsBodyProps> = ({ booking }) => {
    return (
        <div className="whitespace-nowrap overflow-y-auto">
            <Text as="h3">Booking Type</Text>
            <div className="flex flex-row space-x-8 mt-6 items-center">
                <div className={`w-44 bg-primary py-3 px-3 flex justify-center text-white rounded-md`}>{booking.bookingType} Booking</div>
            </div>
            {booking.bookingType === "Guest" && (
                <>
                    <Text as="h3">Cardholder Unique ID</Text>
                    <Text className="text-secondaryText">{booking.gallagherId}</Text>
                </>
            )}
            {booking.bookingType === "Guest" && booking.tenant === "VALET" &&(
                <>
                    <Text as="h3">Customer Phone number</Text>
                    <Text className="text-secondaryText">{booking.phone}</Text>
                </>
            )}
            <Text className="mt-10" as="h3">{booking.bookingType === "Single" ? "Booking Date" : "Booking Validity"}</Text>
            <Text className="mt-6 text-secondaryText">{booking.bookingType === "Single" ? getDate(booking.startTime) : "Valid From " + getDate(booking.originalBooking?.startTime!) + " to " + getDate(booking.originalBooking?.endTime!)}</Text>
            <div className="flex flex-row space-x-8 items-center">
                <div className="flex flex-col">
                    <Text as="h3">Start Time</Text>
                    <Text className="text-secondaryText">{getTime(booking.startTime)}</Text>
                </div>
                <div className="flex flex-col">
                    <Text as="h3">End Time</Text>
                    <Text className="text-secondaryText">{getTime(booking.endTime)}</Text>
                </div>
            </div>
            <Text className="mt-10" as="h3">Current Status</Text>
            <div className="mt-4">
                <div className={`${getColor(booking.status)} w-44 bg-primary py-3 px-3 flex justify-center rounded-md`}>{booking.status} Booking</div>
            </div>
            <Text className="mt-10" as="h3">Events</Text>
            <div className="flex flex-row space-x-8 mt-4 overflow-x-auto whitespace-nowrap items-center">
                {booking.events && booking.events.length > 0 ? (
                    booking.events.map((events, index) => (
                        <EventsChip status={events.status} type={events.type} timestamp={events.timestamp} key={events.status} />
                    ))
                ) : (
                    <Text className="text-secondaryText">No events yet</Text>
                )}
            </div>
            <Text className="mt-10" as="h3">Available Plates</Text>
            <div className="flex flex-row space-x-8 mt-6 items-center">
                {booking.plate && Array.isArray(booking.plate) && booking.plate.length > 0 ? (
                    booking.plate.map((plate, index) => (
                        // Ensure plates are not empty
                        plate !== "" && <Button variant="default" className="w-44 text-white" key={index}>{plate}</Button>
                    ))
                ) : (
                    <Text className="text-secondaryText">No available plates</Text>
                )}
            </div>
            <div className="mt-32">
                {/* Additional content */}
            </div>
        </div>
    );
}

export default BookingDetailsBody;
